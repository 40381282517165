import {PRODUCTS,SUPPLIERS} from "./dataTypes"
export const initialData = {
    modelData: { 
    [PRODUCTS]: [ 
                { id: 1, name: "Iphone 6", 
                category: "Mobile Phones", price: 205,amount: 1 },
                { id: 2, name: "Iphone X", 
                    category: "Mobile Phones", price: 486.95,amount: 1 },
                { id: 3, name: "Iphone 11", category: "Mobile Phones", price: 500.50,amount:1 }
            ],
            [SUPPLIERS]:[
              { id: 1, name: "Iphone 6", 
                category: "Mobile Phones", price: 205,amount: 1 },
                { id: 2, name: "Iphone X", 
                    category: "Mobile Phones", price: 486.95,amount: 1 },
                { id: 3, name: "Iphone 11", category: "Mobile Phones", price: 500.50,amount:1 }
            ],},
            stateData: {
                editing:false,
                selectedId: -1,
                selectedType:PRODUCTS
            },
            newStore: { 
                cart: [], cartItems: 0, cartPrice: 0 

            }

        }