import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CartDetailsRows } from "./CartDetailsRows";
import {TableConnector} from './store/TableConnector';
import {connect} from 'react-redux';
import {addToCart, removeFromCart, clearCart} from './store';
import {PRODUCTS} from './store/dataTypes';

const ConnectedCartDetails = TableConnector(PRODUCTS,CartDetailsRows)

const mapStateToProps = (storeData) => ({
    products:storeData.products,
    cartItems:storeData.cartItems,
        cartPrice:storeData.cartPrice,
        cart:storeData.cart


})

const mapDispatchToProps = {
        addToCart, removeFromCart, clearCart
}

const connectFunction = connect(mapStateToProps,mapDispatchToProps)
export const CartDetails =connectFunction(
class   extends Component {

    getLinkClasses = () => `btn btn-secondary m-1 
        ${this.props.cartItems === 0 ? "disabled": ""}`;
        
    render() {
        return <div className="m-3">
            <h2 className="text-center">Your Cart</h2>
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Quantity</th>
                        <th>Product</th>
                        <th className="text-right">Price</th>
                        <th className="text-right">Subtotal</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                   < ConnectedCartDetails/>
                </tbody>
            </table>
            <div className="text-center">
                <Link className="btn btn-primary m-1" to="/shop">
                    Continue Shopping
                </Link>
                <Link className={ this.getLinkClasses() } to="/shop/checkout">
                    Checkout                
                </Link>
            </div>
        </div>
    }
})