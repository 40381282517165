import { createStore, combineReducers } from "redux";
import modelReducer from "./modelReducer";
import stateReducer from "./stateReducer";
import  CartReducer from './CartReducer';
export default createStore(combineReducers(
    {
        modelData: modelReducer, 
        stateData: stateReducer,
        newStore :  CartReducer
    }));

export { saveProduct, saveSupplier, deleteProduct, deleteSupplier,addToProducts } 
    from "./modelActionCreators";
    export {addToCart, updateCartAmount, removeFromCart, clearCart} from './CartActionCreators'