import {STORE,UPDATE,DELETE,SORT} from './modelActionTypes';
import {initialData} from './initialData';
import sweetalert from 'sweetalert';

export default function(storeData,action) {
	switch(action.type) {
		case STORE: 
		sweetalert('Success!', 'Product Stored!', 'success')
		return {
			...storeData,
			[action.dataType]:storeData[action.dataType].concat([action.payload]),
		}
		case UPDATE:
		return{
			...storeData,
			[action.dataType]:storeData[action.dataType].map(stored=>
				action.payload.id===stored.id ? action.payload:stored)

		}
		case DELETE:
  		sweetalert('Success!', 'Product Deleted!', 'success')
		return {
			...storeData,
			[action.dataType]:storeData[action.dataType].filter(p=>p.id !== action.payload),

		}
		case SORT:{
			let obj={};
			storeData[action.dataType]
		  		.concat([action.payload])	
		  		.forEach((item)=>{
            let id=item.id;
item.amount=Number(item.amount);
            if(obj[id]==null){

                return obj[id]=item

            }
              obj[id].amount=obj[id].amount+1
                    })
		  		 let updatedP=[];
        Object.keys(obj).forEach((key)=>{
            updatedP.push(obj[key]);
        })

              sweetalert('Success!', 'Products amount is increased!', 'success');

      
		 
		  return { 
		  	...storeData,
		  		[action.dataType]:updatedP,
		  	}
		  }
//.map((stored=>action.payload.id===stored.id ? {...stored,amount:action.payload.amount++}:stored))}
   //CartReducer

		default:
		return storeData || initialData.modelData;
	}
}