import React, { Component } from "react";
import {BrowserRouter as Router,Route,NavLink,Switch,Redirect} from 'react-router-dom';
import {ProductDisplay} from './ProductDisplay';
import {SupplierDisplay} from './SupplierDisplay';
import {CartDetails} from './CartDetails';
import {Checkout} from './Checkout';
export class Selector extends Component {
/*
    constructor(props) {
        super(props);
        this.state = {
            selection: React.Children.toArray(props.children)[0].props.name
        }
    }
    
    setSelection = (ev) => {
        ev.persist();
        this.setState({ selection: ev.target.name});
    }
*/
    render() {
        return <Router>
        <div className="container-fluid">
            <div className="row">
                <div className="col-4">
        <div><NavLink className=" btn btn-block btn-primary"
                activeClassName="active"

         to="/products">Products</NavLink>
         </div>
        <div>
        <NavLink className=" btn btn-block btn-primary"
        activeClassName="active"
         to="/storage">Storage</NavLink></div>
                </div>
                <div className="col-8">
                <Switch>
                <Route path="/products" component={ProductDisplay}/>
                <Route path="/storage"  component={SupplierDisplay}/>
                <Route path="/shop/cart" component={CartDetails}  />  
                <Route path="/shop/checkout" component={Checkout}  />  

                <Redirect to ="/products" />

                    </Switch>                 
                </div>
            </div>
        </div>
        </Router>
    }
}