
import {ActionTypes} from './CartActionTypes';

export const addToCart = (product,amount) =>({
	type:ActionTypes.CART_ADD,
	payload:{
		product,amount:product.amount
	}
})

export const updateCartAmount = (product,amount) =>({
	type:ActionTypes.CART_UPDATE,
	payload:{
		product:product,
		amount:amount,
	}
})

export const removeFromCart = (product) => ({
	type:ActionTypes.CART_REMOVE,
	payload:product
})

export const clearCart = () =>({
	type:ActionTypes.CART_CLEAR
})
