import React, { Component } from "react";
import {TableConnector} from './store/TableConnector';
import {connect} from 'react-redux';
import {addToCart, updateCartAmount, removeFromCart, clearCart} from './store';
import {PRODUCTS} from './store/dataTypes';
import {CheckoutCart} from './CheckoutCart';
const ConnectedCartCheckout = TableConnector(PRODUCTS,CheckoutCart)

const mapStateToProps = (storeData) => ({
    products:storeData.products,
    cartItems:storeData.cartItems,
        cartPrice:storeData.cartPrice,
        cart:storeData.cart


})

const mapDispatchToProps = {
        addToCart, updateCartAmount, removeFromCart, clearCart
}

const connectFunction = connect(mapStateToProps,mapDispatchToProps)
export const Checkout =connectFunction(
class   extends Component {
	
 render() {

        return <div>
       <p style={{ fontWeight: 'bold' }}> Thank you for shopping </p>  
       <ConnectedCartCheckout/> 
        </div>
    }
})