import {PRODUCTS,SUPPLIERS} from './dataTypes';
import {STORE,UPDATE,DELETE,SORT} from './modelActionTypes';
let idCounter =4;

const createSaveEvent =(dataType,payload) =>{
	if(!payload.id) {
		return {
			type:STORE,
			dataType:dataType,
			payload:{...payload,id:idCounter++}
		}
	}
	else {
		return {
			type:UPDATE,
			dataType:dataType,
			payload:payload
		}
	}
}


export const saveProduct =(product) =>{
	return createSaveEvent(PRODUCTS,product);
}

export const saveSupplier =(supplier) =>{
	return createSaveEvent(SUPPLIERS,supplier);
}

export const addToProducts = (product) =>({
			type:SORT,
			dataType:PRODUCTS,
			payload:{...product,id:product.id}
		
	})


export const deleteProduct = (product) =>({
	type:DELETE,
	dataType:PRODUCTS,
	payload:product.id
})
export const deleteSupplier = (supplier) =>({
	type:DELETE,
	dataType:SUPPLIERS,
	payload:supplier.id
})
//cartActionCreators
