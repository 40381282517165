import React, { Component } from "react";

export class SupplierTableRow extends Component {

    render() {
        let s = this.props.supplier;
        return <tr>
           <th scope="row">{s.id}</th>
            <td>{ s.name }</td>
            <td>{ s.category}</td>
            <td className="text-right">${ Number(s.price).toFixed(2) }</td>
            <td>{s.amount}</td>
            <td>
                <button className="btn  btn-warning m-1"
                    onClick={ () => this.props.editCallback(s) }>
                        Edit
                </button>
                <button className="btn btn-danger m-1"
                    onClick={ () => this.props.deleteCallback(s) }>
                        Delete
                    </button>                    
                    <button className="btn  btn-warning m-1" 
                    disabled={this.props.isActive}

                        onClick={() => this.props.addCallback(s)}>
                        AddToStore
                        </button>

            </td>
        </tr>
    }
}