import { connect } from "react-redux";
import { startEditingProduct, startEditingSupplier } from "./stateActions";
import { deleteProduct, deleteSupplier,addToProducts } from "./modelActionCreators";
import { PRODUCTS, SUPPLIERS } from "./dataTypes";
import { addToCart, updateCartAmount, removeFromCart, clearCart } from './CartActionCreators';

export const TableConnector = (dataType, presentationComponent) => {

    const mapStateToProps = (storeData) => ({
        products: storeData.modelData[PRODUCTS],
        suppliers: storeData.modelData[SUPPLIERS],
        cartItems:storeData.newStore.cartItems,
        cartPrice:storeData.newStore.cartPrice,
         cart:storeData.newStore.cart
        })
    
    
    const mapDispatchToProps = {
        editCallback: dataType === PRODUCTS 
            ? startEditingProduct : startEditingSupplier,
        deleteCallback: dataType === PRODUCTS ? deleteProduct : deleteSupplier,
                addCallback:addToProducts,
         addToCart:addToCart, updateCartAmount, removeFromCart, clearCart
    }

    return connect(mapStateToProps, mapDispatchToProps)(presentationComponent);
}