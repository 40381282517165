import React, { Component } from "react";
import { ProductTable } from "./ProductTable"
import {connect} from 'react-redux';
import {deleteProduct} from './store';
import {addToCart, updateCartAmount, removeFromCart, clearCart} from './store';
import {PRODUCTS} from './store/dataTypes';
import {TableConnector} from './store/TableConnector';
import {CartSummary} from './CartSummary';

const ConnectedTable = TableConnector(PRODUCTS,ProductTable);
const ConnectedCart = TableConnector(PRODUCTS,CartSummary);



const mapStateToProps = (storeData) => ({
	products:storeData.products,
	cartItems:storeData.cartItems,
        cartPrice:storeData.cartPrice,
        cart:storeData.cart


})

const mapDispatchToProps = {
	deleteCallback:deleteProduct,
	    addToCart, updateCartAmount, removeFromCart, clearCart
}

const connectFunction = connect(mapStateToProps,mapDispatchToProps)
export const ProductDisplay =connectFunction(
	class extends Component{
		constructor(props){
			super(props);
			this.state = {
				showEditor:false,
				selectedProduct:null
			}
		}
 render() {

            return <div className="m-2">
            <ConnectedTable/> 
            <ConnectedCart />
            </div>
        }
	})


   
    
