import {ActionTypes} from './CartActionTypes';
import { initialData } from "./initialData";


export default function(storeData,action) {

	switch(action.type) {
case  ActionTypes.CART_ADD:
 let existing = storeData.cart.find(item => item.product.id === action.payload.product.id);
 if(action.payload.product.amount>0)
            {	if (existing) {
                                        existing.amount += action.payload.amount;
            
                                    } else {                                    	
                                        storeData.cart = [...storeData.cart,action.payload];
                                        console.log(action.payload);
            
                                    }
                                    storeData.cartItems += action.payload.amount;
                                    storeData.cartPrice += action.payload.product.price * action.payload.amount; 
                                     action.payload.product.amount-=action.payload.product.amount
            }
         
return{...storeData

};




/*case ActionTypes.CART_UPDATE:
storeData.cart = storeData.cart.map(item => {
	const diff = action.payload.amount-item.amount;
	if(item.product.id === action.payload.product.id && action.payload.amount>0 ){
		storeData.cartItems += diff;
		storeData.cartPrice += (item.product.price * diff);
		return action.payload;
	}
	else {
		return item;
	}

})

return {...storeData};
*/
 case ActionTypes.CART_REMOVE:
 let selection = storeData.cart.find(item =>
 	item.product.id === action.payload.id);
    storeData.cartItems -= selection.amount;
    storeData.cartPrice -= selection.amount * selection.product.price;
    storeData.cart = storeData.cart.filter(item => item !== selection);
return {...storeData,products:action.payload.product };
	
case ActionTypes.CART_CLEAR:
return {storeData,cart:[],cartItems:0,cartPrice:0}

		default:
		return storeData || initialData.newStore;
	}
}