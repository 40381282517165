import React, { Component } from "react";


export class CheckoutCart extends Component {
 render() {
return <div>
			<h1>Total:</h1>
			<h2>${this.props.cartPrice.toFixed(2)}</h2>
			</div>
		}
	}