import React, { Component } from "react";

export class ProductTableRow extends Component {

    render() {
        let p = this.props.products;

        return <tr>
            <td>{ p.id }</td>
            <td>{ p.name }</td>
            <td>{ p.category}</td>
            <td className="text-right">${ Number(p.price).toFixed(2) }</td>
            <td>{p.amount}</td>
            <td>
                <button className="btn btn-sm btn-danger m-1"
                    onClick={ () => this.props.deleteCallback(p) }>
                        Delete
                    </button>   
                    
                     <button className="btn btn-sm btn-danger m-1"
                    onClick={ () => this.props.addToCart(p) }>
                        Add To Cart
                    </button>   
                    
                                   
            </td>
        </tr>
    }
}