
import React, { Component } from "react";
import { ProductTableRow } from "./ProductTableRow";

export class ProductTable extends Component {

    render() {
        return  <table className="table table-dark table-striped table-bordered  table-hover">
                <thead>
                    <tr>
                        <th scope="col" colSpan="6"
                                className="bg-primary text-white text-center h4 ">
                            Products
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">ID</th><th>Name</th><th>Category</th>
                        <th className="text-right">Price</th>
<th>Amount</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                   
                    {
                        this.props.products.map(p => 
                            <ProductTableRow
                            products={ p } 
                                key={ p.id }
                                deleteCallback={ this.props.deleteCallback }
                               addToCart={ this.props.addToCart  }
                                 />

                                 )
                               }
                                
                    
                </tbody>
            </table>        

    }
}
