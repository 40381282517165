import React, { Component } from "react";
import { SupplierTableRow } from "./SupplierTableRow";

export class SupplierTable extends Component {

    render() {
        return <div className="table-responsive">
        <table className="table table-dark table-striped table-bordered  table-hover">
                <thead>
                    <tr>
                        <th colSpan="6" scope="col"
                                className="bg-primary text-white text-center h4">
                            WareHouse
                        </th>
                    </tr>
                    <tr>
                        <th scope="row">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Price</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.suppliers.map(s => 
                            <SupplierTableRow supplier={ s } 
                                key={ s.id }
                                editCallback={ this.props.editCallback }
                                deleteCallback={ this.props.deleteCallback } 
                                addCallback={this.props.addCallback}

                                />)
                    }
                </tbody>
            </table>        
            </div>
    }
}